import { HashLink as Link } from "react-router-hash-link";
import "./style.css";
import "./custom.css";
import "./HeaderView.css";

const HeaderView = () => {
  return (
    <header
      id="header"
      className="transparent-header dark"
      data-sticky-class="not-dark"
      data-responsive-class="not-dark"
      data-sticky-logo-height="80"
      data-sticky-menu-padding="29"
    >
      <div id="header-wrap">
        <div className="container">
          <div className="header-row justify-content-lg-between">
            <div id="logo" className="col-auto me-lg-0 px-0">
              <Link to="/">
                <img
                  src={require("../supporting_files/images/logo.svg").default}
                  alt="Fluff Money Logo"
                />
              </Link>
            </div>

            <div id="primary-menu-trigger">
              <svg className="svg-trigger" viewBox="0 0 100 100">
                <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
                <path d="m 30,50 h 40"></path>
                <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
              </svg>
            </div>

            <nav className="primary-menu with-arrows not-dark px-0">
              <ul
                className="menu-container one-page-menu"
                data-easing="easeInOutExpo"
                data-speed="1250"
                data-offset="160"
              >
                <div className="dropdown">
                  <li className="menu-item">
                    <div className="menu-link dropdown-main-button">NFTs ▾</div>
                  </li>
                  <div className="dropdown-container">
                    <a
                      className="menu-sublink"
                      href="https://magiceden.io/ordinals/marketplace/pryptocunks"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PryptoCunks
                    </a>
                    <Link
                      className="menu-sublink"
                      to="/embc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      EMBC
                    </Link>
                    <Link
                      className="menu-sublink"
                      to="/fatfrogs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Fat Frogs
                    </Link>
                    <a
                      className="menu-sublink"
                      href="https://magiceden.io/ordinals/marketplace/mdspatavtoitpanstansicbbd"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Barfs
                    </a>
                    <a
                      className="menu-sublink"
                      href="https://inscribenow.io/collections/f69c2c7d17c2fa2b"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Lameiens
                    </a>
                    <a
                      className="menu-sublink"
                      href="https://rarible.com/fluffmoney"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Rarible
                    </a>
                  </div>
                </div>
                <div className="dropdown">
                  <li className="menu-item">
                    <div className="menu-link dropdown-main-button">FLUF ▾</div>
                  </li>
                  <div className="dropdown-container">
                    <a
                      className="menu-sublink"
                      href="/fluf/buy"
                      target="_blank"
                    >
                      Buy
                    </a>
                    <a className="menu-sublink" href="/fluf/stake">
                      Stake
                    </a>
                    <a
                      className="menu-sublink"
                      href="/fluf/charts"
                      target="_blank"
                    >
                      Charts
                    </a>
                    <a className="menu-sublink" href="/fluf">
                      About
                    </a>
                    <a
                      className="menu-sublink"
                      href="/fluf/whitepaper.pdf"
                      target="_blank"
                    >
                      Whitepaper
                    </a>
                    <a className="menu-sublink" href="/fluf#contracts">
                      Contracts
                    </a>
                    <a
                      className="menu-sublink"
                      href="https://bscscan.com/token/0x5285E2598FD8eBEAbF808AD5561Be7F03E1c78af"
                      target="_blank"
                      rel="noreferrer"
                    >
                      BSCScan
                    </a>
                  </div>
                </div>
                <li className="menu-item">
                  <a
                    className="menu-link"
                    href="https://geniidata.com/user/fluffmoney/pizzamap-dashboard-bitcoin-ordinals"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>Pizzamap</div>
                  </a>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/ompt">
                    <div>OMPT</div>
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/app">
                    <div>App</div>
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/#roadmap">
                    <div>Roadmap</div>
                  </Link>
                </li>

                <li>
                  <div id="social-links">
                    <a
                      href="https://x.com/fluffmoney"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../supporting_files/images/social_media_logos/x.svg")
                            .default
                        }
                        height="24"
                        alt="X"
                      />
                    </a>

                    <a
                      href="https://t.me/fluff_money"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../supporting_files/images/social_media_logos/telegram.svg")
                            .default
                        }
                        height="24"
                        alt="Telegram"
                      />
                    </a>

                    <a
                      href="https://instagram.com/fluffmoney"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../supporting_files/images/social_media_logos/instagram.svg")
                            .default
                        }
                        height="24"
                        alt="Instagram"
                      />
                    </a>

                    <a
                      href="https://discord.gg/HXqgcgRev4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../supporting_files/images/social_media_logos/discord.svg")
                            .default
                        }
                        height="24"
                        alt="Discord"
                      />
                    </a>

                    <a
                      href="https://youtube.com/@fluffmoney"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../supporting_files/images/social_media_logos/youtube.svg")
                            .default
                        }
                        height="24"
                        alt="YouTube"
                      />
                    </a>

                    <a
                      href="https://warpcast.com/fluffmoney"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../supporting_files/images/social_media_logos/farcaster.svg")
                            .default
                        }
                        height="24"
                        alt="Farcaster"
                      />
                    </a>

                    <a
                      href="https://bsky.app/profile/fluffmoney.bsky.social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../supporting_files/images/social_media_logos/bluesky.svg")
                            .default
                        }
                        height="24"
                        alt="Bluesky"
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderView;
