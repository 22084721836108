import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./custom.css";
import "./style.css";
import "./HomeView.css";
import mainBg from "../supporting_files/images/main_bg.jpg";

const OMPTView = () => {
  useEffect(() => {
    document.title = "OMPT - Fluff Money";
  });

  return (
    <>
      <section id="content">
        <div className="content-wrap">
          <Container>
            <Row className="align-items-center">
              <Col md className="center">
                <div className="emphasis-title bottommargin-sm">
                  <h2>Ordimap Token (OMPT)</h2>
                </div>
                <div className="lead">
                  <p>
                  A CBRC-20 token airdropped to all Ordimap holders, based on a
                  snapshot (Feb 18, 2024, 11:39 AM). Not affiliated with
                  Ordimap.
                  </p>
                  <p>
                    Every Ordimap holder contained in the snapshot got 0.69
                    OMPT.
                  </p>
                  <p>The total supply is 1000.</p>
                </div>
              </Col>
              <Col md className="center">
                <img
                  src={require("../supporting_files/images/ompt/miklos.jpg")}
                  style={{ maxWidth: "300px" }}
                  alt="Ordimap Token"
                  data-animate="fadeInRight"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default OMPTView;
