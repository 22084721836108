import { useEffect } from "react";
import mainBg from "../supporting_files/images/main_bg.jpg";

const FlufView = () => {
  useEffect(() => {
    document.title = "FLUF - Fluff Money";
  });

  return (
    <div id="wrapper" className="clearfix">
      <section
        id="slider"
        className="slider-element slider-parallax min-vh-60 min-vh-md-100 include-header"
      >
        <div
          className="slider-inner-element"
          style={{
            background: "url(" + mainBg + ") center center no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="vertical-middle slider-element-fade">
            <div className="container dark center">
              <div style={{ margin: "32px 0" }}>
                <div className="emphasis-title">
                  <p>
                    <img
                      id="main-logo"
                      src={
                        require("../supporting_files/images/logo.svg").default
                      }
                      height="100"
                    />
                  </p>
                  <h1>Fluffy Puppy Token</h1>
                  <h3 id="slogan" className="font-body">
                    The Currency of the Fluff Money Ecosystem
                  </h3>
                </div>
                <div id="main-buttons" style={{ margin: "auto" }}>
                  <a
                    href="/fluf/buy"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Buy
                  </a>
                  <a
                    href="/fluf/stake"
                    className="button button-large button-light"
                  >
                    Stake
                  </a>
                  <a
                    href="/fluf/charts"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Charts
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div id="contracts" className="page-section">
              <div className="row clearfix align-items-center center">
                <div className="emphasis-title">
                  <h2>Contracts</h2>
                </div>

                <h3>SOL (actively in use)</h3>

                <p className="contract-address">
                  <a
                    href="https://etherscan.io/token/0x38c97140C3A7F0123D46e031027F22037076F98a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    7F5274YYzuBhhmCpAHLKf5qn1KaDS9CwxL6wwXFTpump
                  </a>
                </p>

                <p>
                  The SOL relaunch was fucked up. I prematurely sold my BSC
                  allocation to fund the SOL version (this wasn't even announced
                  properly), which went to 150k and back to 6k!! This resulted
                  in both SOL and BSC people getting angry because they saw two
                  tokens that dumped massively.
                </p>

                <p>
                  Lots of people did not fill out the airdrop form properly (or
                  at all) so only 3 people received the airdrop (and even they
                  received it after the 150k spike, which wasn't too ideal
                  either.) I even fucked up with not paying for Dexscreener in
                  time and sending funds to burn to
                  1nc1nerator11111111111111111111111111111111 instead of burning
                  them properly (I burned 28.2%).
                </p>

                <p>Moral of the story: prepare for the WORST</p>

                <hr />

                <h3>BSC (legacy, not maintained)</h3>
                <p>
                  The BSC token had 5 different versions. (I know, looks
                  horrible, but I cant do anything at this point.)
                </p>
                <p>
                  All v4 holders got v5 at the time of the release, all v4
                  holders got v3 and so on.
                </p>

                <div>
                  <strong>
                    FLUF v5 (made because there was a bug in FLUF v4's code that
                    made transaction fees rise after each transaction):
                  </strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0x5285E2598FD8eBEAbF808AD5561Be7F03E1c78af"
                      target="_blank"
                      rel="noreferrer"
                    >
                      0x5285E2598FD8eBEAbF808AD5561Be7F03E1c78af
                    </a>
                  </p>
                </div>
                <div>
                  <strong>
                    FLUF v4 (added new features to the contract - most
                    importantly, a time-based buy/sell limit):
                  </strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0xf967a0Bc934a432C1De2b2329Ba9bdd78b5605B8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      0xf967a0Bc934a432C1De2b2329Ba9bdd78b5605B8
                    </a>
                  </p>
                </div>
                <div>
                  <strong>FLUF v3 (made to add more BNB to the pool):</strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0xF3B978e37E85391E8CAd6bd06A6a093C06f54eDC"
                      target="_blank"
                      rel="noreferrer"
                    >
                      0xF3B978e37E85391E8CAd6bd06A6a093C06f54eDC
                    </a>
                  </p>
                </div>
                <div>
                  <strong>
                    FLUF v2 (made because it's not possible to rename an ERC20
                    token):
                  </strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0xa153049830ef4f8af5ca7272f8a3aa056bd21914"
                      target="_blank"
                      rel="noreferrer"
                    >
                      0xa153049830ef4f8af5ca7272f8a3aa056bd21914
                    </a>
                  </p>
                </div>
                <div>
                  <strong>FLUF v1 (YIAY):</strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0x3a42a4d32a811ce89e9bdc69a2d1c517314055aa"
                      target="_blank"
                      rel="noreferrer"
                    >
                      0x3a42a4d32a811ce89e9bdc69a2d1c517314055aa
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FlufView;
